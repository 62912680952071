<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-profile-main ml-3 mr-3 pl-8 pr-8">
      <v-row class="">
        <v-col class="text-left mx-0 px-0" cols="12">
          <h1 class="profile__title">{{ $translate("profile.title") }}</h1>
        </v-col>
        <v-col class="text-left mx-0 px-0" cols="6">
          <v-layout justify-end>
            <v-btn rounded color="#466be3" @click="openModal()" dark class="mt-3">
              <strong> {{ $translate("profile.buttonEdit") }} </strong></v-btn>
          </v-layout>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" class="mx-0 pl-0">
          <v-card outlined elevation="0" class="pt-12 pb-12" style="border-radius: 15px">
            <v-card-text class="pt-5 pb-5">
              <div>
                <v-hover v-slot="{ hover }">
                  <v-badge class="profile__badge" color="#3b5bc0" icon="mdi-camera" overlap bottom offset-x="30"
                    offset-y="30">
                    <v-avatar @click="changeImg = true" style="
                        cursor: pointer;
                        border-radius: 50%;
                        border: 2px solid;
                        border-color: #466be3;
                      " class="img-profile-g-1 v-step-18">
                      <v-img class="pt-2" :src="img">
                        <v-expand-transition>
                          <div v-if="hover"
                            class="d-flex transition-fast-in-fast-out black v-card--reveal text-h2 white--text"
                            style="height: 100%">
                            <v-btn icon @click="changeImg = true">
                              <v-icon color="#fff"> mdi-camera </v-icon>
                            </v-btn>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-avatar>
                  </v-badge>
                </v-hover>
              </div>


              <div class="d-flex flex-column" style="align-items: center">

                <v-btn rounded color="#466be3" @click="openModal()" dark class="mt-5" small>
                  <strong>
                    {{ $translate("profile.updateData") }} <v-icon small> mdi-pencil </v-icon>
                  </strong></v-btn>
                <v-btn rounded color="#466be3" dark @click="generateCarnet()" class="mt-5" small>
                  <strong>  {{ $translate("profile.generateCard") }} </strong>
                </v-btn>
              </div>

              <p class="profile__subtitle txt-transform mt-5">
                {{ info.name }} {{ info.lastname }}
              </p>
              <p class="profile__subtitle_ligth mt-5">
                {{ toTitleCase(info.profile.name) }}
              </p>

              <v-row class="mt-7">
                <v-col cols="4">
                  <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                    <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                      <div class="display-center">
                        <img class="pb-2" width="40" :src="origin + '/icon/calendar-profile.svg'" />
                      </div>
                      <p class="profile__subtitle mt-2">{{ workTime }}</p>
                      <p class="profile__subtitle_ligth">{{ $translate("profile.workedMonths") }}</p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                    <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                      <div class="display-center">
                        <img class="pb-2" width="40" :src="origin + '/icon/star-profile.svg'" />
                      </div>
                      <p class="profile__subtitle mt-2">{{ birthday }}</p>
                      <p class="profile__subtitle_ligth">
                        {{ $translate("profile.daysToBirthday") }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                    <div class="pt-6 pb-6 pl-4 pr-4 profile__div_number container-item-altura">
                      <div class="display-center">
                        <img class="pb-2" width="40" :src="origin + '/icon/users-profile.svg'" />
                      </div>
                      <p class="profile__subtitle mt-2">
                        {{ references.length }}
                      </p>
                      <p class="profile__subtitle_ligth"> {{ $translate("profile.myReferrals") }}</p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7" class="mx-0 pr-0">
          <v-card outlined elevation="0" style="border-radius: 15px">
            <v-card-text>
              <!-- <v-toolbar flat> -->
              <!-- <template v-slot:extension> -->
              <v-tabs color="#466BE3" v-model="tabs" fixed-tabs>
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#mobile-tabs-5-1">
                  <v-icon> mdi-account-search-outline </v-icon> &nbsp; {{ $translate("profile.indexTab.personnel") }}
                </v-tab>

                <v-tab href="#mobile-tabs-5-2">
                  <v-icon> mdi-phone-message-outline </v-icon> &nbsp; {{ $translate("profile.indexTab.contact") }}
                </v-tab>

                <v-tab href="#mobile-tabs-5-3">
                  <v-icon>mdi-briefcase-variant-outline</v-icon> &nbsp;
                  {{ $translate("profile.indexTab.contractual") }}
                </v-tab>

                <v-tab href="#mobile-tabs-5-4">
                  <v-icon>mdi-folder-outline</v-icon> &nbsp; {{ $translate("profile.indexTab.followUp") }}
                </v-tab>
              </v-tabs>
              <!-- </template>
</v-toolbar> -->
              <!-- perfil Desktop -->
              <v-tabs-items v-model="tabs" class="container-altura">
                <v-tab-item :value="'mobile-tabs-5-1'">
                  <v-card class="pb-16 mt-1" flat>
                    <v-card-text>
                      <!-- <p class="profile__subtitle_data text-left mt-3">
                        Mi perfil empresarial
                      </p> -->
                      <p class="profile__subtitle_data_2 text-left mt-6">
                        {{ $translate("profile.personal.personalInformation") }}
                      </p>

                      <v-row class="mt-6">
                        <v-col class="text-left capi-case" cols="6">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.fullNames") }}
                            </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.name }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left capi-case" cols="6">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.lastNames") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.lastname }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.document_type">
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.personal.documentType") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.document_type }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.document_number">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.documentNumber") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.document_number }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6"  v-if="info.document_expedition_date">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.documentIssuanceDate") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.document_expedition_date }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.birth_date">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.birthDate") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.birth_date }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.gender">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.gender") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.gender }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6"  v-if="info.age">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.age") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.age }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.civil_status">
                          <p class="mb-1 profile__data_title">
                            <i>{{ $translate("profile.personal.maritalStatus") }}  </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.civil_status }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6"  v-if="info.academic_level">
                          <p class="mb-1 profile__data_title">
                            <i>{{ $translate("profile.personal.academicLevel") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.academic_level }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.eps">
                          <p class="mb-1 profile__data_title">
                            <i>{{ $translate("profile.personal.healthPromotingEntity") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.eps }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.pension">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.personal.pensionFund") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.pension }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="'mobile-tabs-5-2'" class="show">
                  <v-card class="pb-16 mt-1" flat>
                    <v-card-text>
                      <!-- <p class="profile__subtitle_data text-left mt-3">
                        Mi perfil empresarial
                      </p> -->
                      <p class="profile__subtitle_data_2 text-left mt-6">
                        {{ $translate("profile.contact.contactInformation") }}
                      </p>

                      <v-row class="mt-6">
                        <v-col class="text-left firstMayus" cols="6" v-if="info.residence_department" >
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.contact.department") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.residence_department }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.residence_city" >
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.city") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.residence_city }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.locality">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.locality") }}  </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.locality }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.residence_neighborhood">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.neighborhood") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.residence_neighborhood }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.address">
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.contact.address") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.address }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left" cols="6"  v-if="info.personal_email">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.personalEmail") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.personal_email }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.stratum">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.socioeconomicStratum") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.stratum }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.housing_type" >
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.housingType") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.housing_type }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6"  v-if="info.phone">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.phone") }}  </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.phone }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="6" v-if="info.local_phone">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contact.landline") }}  </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.local_phone }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>


                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="'mobile-tabs-5-3'">
                  <v-card class="pb-1" flat>
                    <v-card-text>
                      <!-- <p class="profile__subtitle_data text-left mt-3">
                        Mi perfil empresarial
                      </p> -->
                      <p class="profile__subtitle_data_2 text-left mt-6">
                        {{ $translate("profile.contractual.contractualInformation") }}
                      </p>

                      <v-row class="mt-6">
                        <v-col class="text-left firstMayus" cols="12" v-if="info.profile.name">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.position") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.profile.name }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="3" v-if="info.profile_type">
                          <p class="mb-1 profile__data_title">
                            <i>{{ $translate("profile.contractual.positionType") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.profile_type }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="3" v-if="info.status.name">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.status") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.status.name }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left" cols="6" v-if="info.email">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.corporateEmail") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.email }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.type_contract">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.contractType") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.type_contract }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6" v-if="info.date_admission">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.entryDate") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.date_admission }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="info.retirement_date != 'Vacio'" class="text-left firstMayus" cols="4">
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.contractual.retirementDate") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt camel-case">
                            {{ info.retirement_date }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="4" v-if="info.contractor">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.contractor") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.contractor }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="4" v-if="info.cc">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.costCenter") }} </i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.cc }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col class="text-left firstMayus" cols="4"  v-if="info.cod_scc">
                          <p class="mb-1 profile__data_title">
                            <i>{{ $translate("profile.contractual.subCostCenter") }}</i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.cod_scc }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col v-if="info.client" class="text-left firstMayus" cols="6" > 
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.client") }}</i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.client }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col v-if="info.campaign" class="text-left firstMayus" cols="6">
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.contractual.campaign") }}</i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.campaign }} - {{ info.campaign_number }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left firstMayus" cols="6"  v-if="info.headquarters">
                          <p class="mb-1 profile__data_title">
                            <i> {{ $translate("profile.contractual.headquarters") }}</i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.headquarters }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>

                        <v-col class="text-left" cols="6"  v-if="info.headquarters_city"> 
                          <p class="mb-1 profile__data_title">
                            <i>  {{ $translate("profile.contractual.headquartersCity") }}</i>
                          </p>
                          <p class="mb-1 profile__data_txt">
                            {{ info.headquarters_city }}
                          </p>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="'mobile-tabs-5-4'">
                  <v-card flat height="540" style="overflow-y: scroll">
                    <v-card-text>
                      <!-- <p class="profile__subtitle_data text-left mt-3">
                        Mi perfil empresarial
                      </p> -->
                      <p class="profile__subtitle_data_2 text-left mt-6">
                        {{ $translate("profile.followUp.trackingInformation") }}
                      </p>

                      <template>
                        <v-timeline align-top dense>
                          <v-timeline-item v-for="data in listAudit" :key="data.id" fill-dot color="#C6C6C6"
                            class="profile__time" small>
                            <v-alert dense color="#FCEDF3">
                              <v-row>
                                <v-col cols="9">
                                  <p class="text-left mb-0 profile__title-alert">
                                    <strong>
                                      Tienes una falla critica de seguimiento
                                    </strong>
                                  </p>
                                  <p class="text-left mb-0 mt-1 profile__data_txt">
                                    Tienes una falta grave en tu última encuesta
                                    de seguimiento
                                  </p>
                                </v-col>
                                <v-col cols="3">
                                  <p class="mb-0 mt-1 profile__title-date">
                                    {{
              new Date(data.created_at)
                .toISOString()
                .substr(0, 10)
            }}
                                  </p>
                                  <v-btn @click="validateRedirect(data)" class="mt-2" color="#2E3E75" text x-small>
                                    <u> Ver más </u>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-alert>
                          </v-timeline-item>
                        </v-timeline>
                      </template>

                      <template v-if="listActionsPlan != 0 && listPerformance != 0">
                        <v-card v-if="listActionsPlan != 0" class="card-info-per text-left mt-3" elevation="0">
                          <v-card-text>
                            <p class="text-blue-actions-p mb-1">
                              Tienes {{ listActionsPlan }} plan(es) de
                              acción(es) pendiente(s)
                            </p>
                            <p class="mb-1">
                              Para registrar un plan de acción:
                            </p>
                            <p class="mb-1">
                              - Ingrese a performing dando clic
                              <v-btn @click="irAppPerforming()" outlined small class="ml-1 mr-1" color="#466be3">
                                <strong> AQUÍ </strong>
                              </v-btn>
                              o en la sección de aplicaciones.
                            </p>
                            <p class="mb-1">
                              - En el menú de clic en la opción de "MIS PLANES
                              DE ACCIÓN".
                            </p>
                            <p class="mb-1">
                              - Seleccione un plan de acción de la lista y
                              adjunte una evidencia.
                            </p>
                          </v-card-text>
                        </v-card>
                        <v-card v-if="listPerformance != 0" class="card-info-per mt-3 mb-4 text-left" elevation="0">
                          <v-card-text>
                            <p class="text-blue-actions-p mb-1">
                              Tienes {{ listPerformance }} evaluación(es)
                              pendiente(s) por responder
                            </p>
                            <p class="mb-1">Para completar las evaluaciones:</p>
                            <p class="mb-1">
                              - Ingrese a performing dando clic
                              <v-btn @click="irAppPerforming()" outlined small class="ml-1 mr-1" color="#466be3">
                                <strong> AQUÍ </strong>
                              </v-btn>
                              o en la sección de aplicaciones.
                            </p>
                            <p class="mb-1">
                              - En la lista de evaluaciones seleccione si es
                              "Desempeño" o "periodo de prueba", de clic en el
                              ícono de
                              <v-icon small> mdi-file-document </v-icon>
                              "Realizar encuesta".
                            </p>
                            <p class="mb-1">
                              - Seleccione las respuestas pertinentes y de clic
                              en "FINALIZAR".
                            </p>
                          </v-card-text>
                        </v-card>
                      </template>
                      <template v-else>
                        <v-alert dense text type="info" class="my-4 mt-8">
                          {{ $translate("profile.followUp.noTrackingInformation") }}
                        </v-alert>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-profile-main mb-10">
      <v-row justify="center" class="mx-0 px-0">
        <v-col class="mt-0 pl-0 pr-0 profile__banner" cols="12">
          <img style="z-index: 1; width: 100%" class="mt-0 img-banner" src="../../assets/img/banner-profile.png" />
          <div class="line"></div>
          <div class="container-img-profile">
            <v-hover v-slot="{ hover }">
              <v-avatar @click="changeImg = true" style="
                  cursor: pointer;
                  border-radius: 50%;
                  border: 2px solid;
                  border-color: #466be3;
                " class="img-profile-g-1 v-step-mobile-25">
                <v-img class="pt-2" :src="img">
                  <v-expand-transition>
                    <div v-if="hover"
                      class="d-flex transition-fast-in-fast-out black v-card--reveal text-h2 white--text"
                      style="height: 100%">
                      <v-btn icon @click="changeImg = true">
                        <v-icon color="#fff"> mdi-camera </v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-avatar>
            </v-hover>
          </div>
          <div class="mt-3 ml-3 mr-3">
            <p class="txt-transform text-title-dark-blue-xl-p pb-1 mb-1" style="text-align: center">
              {{ info.name }} {{ info.lastname }}
            </p>
            <p class="txt-transform pb-1 mb-0" style="text-align: center">
              {{ toTitleCase(info.profile.name) }}
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col style="text-align: center" cols="4">
              <img class="pb-2" :src="origin + '/icon/calendar-profile.svg'" />
              <p class="mb-0 pb-0 total-num">{{ workTime }}</p>
              <p class="profile__subtitle_ligth">Meses trabajados</p>
            </v-col>
            <v-col style="text-align: center" cols="4">
              <img class="pb-2" :src="origin + '/icon/star-profile.svg'" />
              <p class="mb-0 pb-0 total-num">{{ birthday }}</p>
              <p class="profile__subtitle_ligth">Días para su cumpleaños</p>
            </v-col>
            <v-col style="text-align: center" cols="4">
              <img class="pb-2" :src="origin + '/icon/users-profilev2.svg'" />
              <p class="mb-0 pb-0 total-num">{{ references.length }}</p>
              <p class="profile__subtitle_ligth">Mis referidos</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-btn @click="generateCarnet()" class="btn-main" dark rounded block>
            <strong> Descargar Carnet </strong>
          </v-btn>
          <v-btn @click="openModal()" class="btn-main my-5" dark rounded block>
            <strong> EDITAR </strong>
          </v-btn>
        </v-col>
        <v-col class="mb-10" cols="12">
          <v-list class="list-profile">
            <v-list-group color="#466BE3" prepend-icon="mdi-account-box-outline" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="list-items v-step-mobile-26">
                    Información básica
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="pl-5 pr-3">
                <v-row>
                  <v-col class="text-left firstMayus" cols="12">
                    <label class="text-label-profile">Nombre </label>
                    <p class="mb-0 camel-case">{{ info.name }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Apellido </label>
                    <p class="mb-0 camel-case">{{ info.lastname }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">
                      Tipo de documento
                    </label>
                    <p class="mb-0">{{ info.document_type }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">Número de documento
                    </label>
                    <p class="mb-0">{{ info.document_number }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">Fecha nacimiento </label>
                    <p class="mb-0">{{ info.birth_date }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Edad </label>
                    <p class="mb-0">{{ info.age }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">Fecha de expedición
                    </label>
                    <p class="mb-0">{{ info.document_expedition_date }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Género </label>
                    <p class="mb-0">{{ info.gender }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Departamento </label>
                    <p class="mb-0">{{ info.residence_department }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Ciudad </label>
                    <p class="mb-0">{{ info.residence_city }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Localidad </label>
                    <p class="mb-0">{{ info.locality }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Barrio </label>
                    <p class="mb-0">{{ info.residence_neighborhood }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Dirección </label>
                    <p class="mb-0">{{ info.address }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Estrato </label><br />
                    <p class="mb-0">{{ info.stratum }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Tipo de vivienda </label><br />
                    <p class="mb-0">{{ info.housing_type }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Celular </label><br />
                    <p class="mb-0">{{ info.phone }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Teléfono </label><br />
                    <p class="mb-0">{{ info.local_phone }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Correo personal </label><br />
                    <p class="mb-0 minus">{{ info.personal_email }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Estado civil </label><br />
                    <p class="mb-0">{{ info.civil_status }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Nivel académico </label><br />
                    <p class="mb-0">{{ info.academic_level }}</p>
                  </v-col>
                  <!-- ----------------------------------------- -->
                </v-row>
              </v-list-item>
            </v-list-group>

            <v-list-group color="#466BE3" prepend-icon="mdi-clipboard-text-outline" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="list-items v-step-mobile-27">
                    Información contractual
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="pl-5 pr-3">
                <v-row>
                  <v-col class="text-left firstMayus" cols="12">
                    <label class="text-label-profile"> Estado </label>
                    <p class="mb-0">{{ info.status.name }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">
                      Correo corporativo
                    </label>
                    <br />
                    <p class="mb-0 minus">{{ info.email }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Cargo </label>
                    <p class="mb-0">{{ info.profile.name }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Tipo de cargo </label>
                    <p class="mb-0">{{ info.profile_type }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Fecha de ingreso </label>
                    <p class="mb-0">{{ info.date_admission }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <v-col v-if="info.retirement_date != 'Vacio'" class="ml-0 pl-0 firstMayus" cols="12">
                      <label class="text-label-profile">
                        Fecha de retiro
                      </label>
                      <p class="mb-0">{{ info.retirement_date }}</p>
                      <v-divider class="mt-1 pb-1"></v-divider>
                    </v-col>

                    <label class="text-label-profile"> Tipo de contrato </label><br />
                    <p class="mb-0">{{ info.type_contract }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Contratante </label><br />
                    <p class="mb-0">{{ info.contractor }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Centro de costos </label><br />
                    <p class="mb-0">{{ info.cc }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile">
                      Sub centro de costos </label><br />
                    <p class="mb-0">{{ info.cod_scc }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <v-col v-if="info.client" class="ml-0 pl-0 firstMayus" cols="12">
                      <label class="text-label-profile"> Cliente </label>
                      <p class="mb-0">{{ info.client }}</p>
                      <v-divider class="mt-1 pb-1"></v-divider>
                    </v-col>

                    <v-col v-if="info.campaign" class="ml-0 pl-0 firstMayus" cols="12">
                      <label class="text-label-profile"> Campaña </label><br />
                      <p class="mb-0">
                        {{ info.campaign }} - {{ info.campaign_number }}
                      </p>
                      <v-divider class="mt-1 pb-1"></v-divider>
                    </v-col>

                    <label class="text-label-profile"> Sede </label><br />
                    <p class="mb-0">{{ info.headquarters }}</p>
                    <v-divider class="mt-1 pb-1"></v-divider>

                    <label class="text-label-profile"> Ciudad de la sede </label><br />
                    <p class="mb-0">{{ info.headquarters_city }}</p>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>

            <!-- Esta informacion se comentara de momento pero si se utilizará -->

            <!-- <v-list-group
              color="#466BE3"
              prepend-icon="mdi-laptop"
              no-action
              class="personal-information"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="list-items v-step-21">
                    Información tecnológica
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="pl-5 pr-3">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="text-left" cols="6">
                        <template v-if="infoTec.mac_addres == null">
                          Información no disponible
                        </template>
                        <template v-else>
                          <label class="text-label-profile"> Sede </label>
                          <label>
                            {{
                              infoTec.sede == "object"
                                ? infoTec.sede
                                : "Indefinido"
                            }}
                          </label>
                          <br />
                          <label class="text-label-profile"> Piso </label>
                          <label>
                            {{
                              infoTec.piso == "object"
                                ? infoTec.piso
                                : "Indefinido"
                            }}
                          </label>
                          <br />

                          <label class="text-label-profile"> Puesto </label>
                          <label> {{ infoTec.puesto }} </label> <br />
                          <label class="text-label-profile"> Mac_addres</label>
                          <label> {{ infoTec.mac_addres }} </label> <br />
                        </template>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group> -->

            <!-- Esta informacion se comentara de momento pero si se utilizará -->

            <!-- <v-list-group
              color="#466BE3"
              prepend-icon="mdi-chart-box-outline"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="list-items v-step-22">
                    Información de seguimiento
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="pl-5 pr-3">
                <v-list-item-content>
                  <v-list-item-title>
                    Información no disponible
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group> -->
          </v-list>
        </v-col>
      </v-row>
    </div>

    <!-- Dialog change image -->
    <v-dialog max-width="400" v-model="changeImg" content-class="overflow">
      <v-card>
        <v-card-title>
          <v-layout justify-center="">
            <h4 class="title-change-img"> {{ $translate("profile.popUp.changeImage") }}</h4>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-avatar size="70" class="profile__border-image">
            <img class="imgAvatar" :src="img" />
          </v-avatar>
          <v-form ref="form">
            <v-file-input :rules="[
              (v) => !!v || 'La imagen es requerida.',
              (value) =>
                !value ||
                value.size < 1097152 ||
                'La imagen debe pesar menos de 1MB',
            ]" accept="image/png, image/jpeg, image/bmp" color="#1E294D"  :label="$translate('profile.popUp.chooseProfilePhoto')" filled
              rounded single-line dense v-model="file" class="mt-3 mb-0"></v-file-input>
          </v-form>
          <p v-if="fileFormat" class="profile__change-image">
            {{ $translate("profile.popUp.fileRestriction") }}
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 m-0">
          <v-row>
            <v-col cols="12" md="6" xs="12" sm="6" lg="6" xl="6">
              <v-btn color="#466be3" block="" rounded outlined @click="
              changeImg = false;
            reset();
            ">
                {{ $translate("profile.popUp.close") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xs="12" sm="6" lg="6" xl="6">
              <v-btn :loading="buttonLoading" color="#466be3" block="" dark rounded @click="
              saveImg();
            reset();
            " class="profile__button">
                 {{ $translate("profile.popUp.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin de change image -->

    <!-- modal temporal -->
    <v-dialog v-model="modalSend" justify="center" max-width="470">
      <v-card class="px-3">
        <v-card-title style="justify-content: center" class="text-h5 request-project">
          {{ $translate("profile.popUp.updateYourData") }}
        </v-card-title>
        <v-card-text class="request-txt-project mb-0 pb-0">
          <img alt="Imagen BRM" width="150" class="mt-1" src="../../assets/img/brm.png" />
          <p class="mt-3" style="font-size: 17px">
            {{ $translate("profile.popUp.updateDescription") }}
          </p>
          <p class="mt-3" style="font-size: 17px">
            {{ $translate("profile.popUp.updateInstruction") }}
            <strong style="font-weight: bold; color: #466be4">{{ $translate("profile.popUp.updateText") }}
              <v-icon small color="#466be4">mdi-pencil</v-icon> </strong>
          </p>
        </v-card-text>
        <v-card-actions class="mx-5 pt-0">
          <v-btn block rounded small class="mb-4" color="#466be3" outlined @click="modalSend = false">
            {{ $translate("profile.popUp.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert :open="alert.open" :text="alert.txt" :title="alert.title" :type="alert.type" :redirect="alert.redirect"
      @close="alert.open = false">
    </Alert>

    <!-- fin  modal temporal -->

    <!-- Editar perfil -->

    <v-dialog v-model="modal.edit" >
      <v-card>
        <v-card-text>
          <v-toolbar dark color="#3957B9">
            <v-btn icon dark @click="modal.edit = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <strong>  {{ $translate("profile.update.dataUpdate") }}</strong>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-text>

        <v-card-text class="mt-5">
          <v-form ref="formE">
            <v-row justify="center">
              <v-col cols="12" xl="8" lg="8" md="10" sm="12">
                <v-row>
                  <v-col cols="12">
                    <p class="profile__subtitle_data text-left mt-3">
                      {{ $translate("profile.update.businessProfile") }}
                    </p>
                    <p class="profile__subtitle_data_2 text-left mt-6">
                      {{ $translate("profile.update.personalInformation") }}
                    </p>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" xl="4" lg="4" md="6" sm="6">     
                    <v-text-field v-model="formEdit.name" :label="$translate('profile.update.firstName')"></v-text-field>
                    
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.lastName" :label="$translate('profile.update.lastName')"></v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.fullName" disabled :label="$translate('profile.update.fullName')"></v-text-field>
                  </v-col>
                  <!-- este -->
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-autocomplete color="#321764" v-model="formEdit.documentType" :items="listDocumentType"
                    :label="$translate('profile.update.documentType')"></v-autocomplete>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.documentNumber" :label="$translate('profile.update.documentNumber')"></v-text-field>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="formEdit.documentExpeditionDate"  :label="$translate('profile.update.documentIssuanceDate')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="formEdit.documentExpeditionDate" @input="menu1 = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="formEdit.birthDate" :label="$translate('profile.update.birthDate')" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="formEdit.birthDate" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field disabled v-model="formEdit.age" :label="$translate('profile.update.age')"></v-text-field>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-select color="#321764" v-model="formEdit.gender" :items="listGender" :label="$translate('profile.update.gender')"></v-select>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-select color="#321764" item-text="name" item-value="id" v-model="formEdit.civilStatus"
                      :items="listCivilStatus" :label="$translate('profile.update.maritalStatus')"></v-select>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-select color="#321764" item-text="name" item-value="id" v-model="formEdit.academicLevel"
                      :items="listLevel" :label="$translate('profile.update.academicLevel')"></v-select>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6" v-if="formEdit.eps">
                    <v-autocomplete
                        color="#321764"
                        v-model="formEdit.eps"
                        :items="[...listEps, formEdit.eps].filter((v, i, a) => a.indexOf(v) === i)"
                        :label="$translate('profile.update.healthPromotingEntity')"
                        :disabled="!listEps.includes(formEdit.eps)"
                      ></v-autocomplete>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6" v-if="formEdit.pension"> 
                    <v-autocomplete
                    color="#321764"
                    v-model="formEdit.pension"
                    :items="[...listPensiones, formEdit.pension].filter((v, i, a) => a.indexOf(v) === i)"
                    :label="$translate('profile.update.pensionFund')"
                    :disabled="!listPensiones.includes(formEdit.pension)"
                  ></v-autocomplete>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12">
                    <p class="profile__subtitle_data_2 text-left mt-6">
                      {{ $translate('profile.update.contactInformation') }}
                    </p>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.personalEmail" :label="$translate('profile.update.personalEmail')"></v-text-field>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.phone" :label="$translate('profile.update.mobile')"></v-text-field>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-text-field v-model="formEdit.localPhone" :label="$translate('profile.update.phone')"></v-text-field>
                  </v-col>

                  <!-- direccion -->

                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6" v-if="formEdit.stratum">
                    <v-select color="#321764" item-text="name" item-value="id" v-model="formEdit.stratum"
                      :items="listStatrum" :label="$translate('profile.update.socioeconomicStratum')"></v-select>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6">
                    <v-select color="#321764" item-text="name" item-value="id" v-model="formEdit.housingType"
                      :items="listTypeH" :label="$translate('profile.update.housingType')"></v-select>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6" v-if="formEdit.residenceDepartament">
                      <v-autocomplete
                      color="#321764"
                      item-text="name"
                      item-value="name"
                      v-model="formEdit.residenceDepartament"
                      :items="[...listDepartament, { name: formEdit.residenceDepartament }].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)"
                      :label="$translate('profile.update.department')"
                      :disabled="!listDepartament.some(item => item.name === formEdit.residenceDepartament)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6" v-if="formEdit.residenceCity">
                    <v-select
                    color="#321764"
                    item-text="name"
                    item-value="name"
                    v-model="formEdit.residenceCity"
                    :items="[...listCities, { name: formEdit.residenceCity }].filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)"
                    :label="$translate('profile.update.city')"
                    :disabled="!listCities.some(item => item.name === formEdit.residenceCity)"
                  ></v-select>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6 " v-if="formEdit.locality">
                    <v-text-field v-model="formEdit.locality" :label="$translate('profile.update.locality')"></v-text-field>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="4" lg="4" md="6" sm="6" v-if="formEdit.residenceNeighborhood">
                    <v-text-field v-model="formEdit.residenceNeighborhood" :label="$translate('profile.update.neighborhood')"></v-text-field>
                  </v-col>
                

                  <!-- direccion -->
                  <v-col class="pt-2 pb-2" cols="12">
                    <p class="profile__subtitle_data_2 text-left mt-6">
                      {{ $translate('profile.update.addressInformation')}}
                      <v-icon color="#466BE3" style="cursor: pointer" @click="instruction = true">
                        mdi-help-circle-outline
                      </v-icon>
                    </p>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-text-field disabled v-model="formEdit.address" :label="$translate('profile.update.address')"></v-text-field>
                  </v-col>
                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-text-field disabled v-model="formEdit.addressDian" :label="$translate('profile.update.dianAddress')"></v-text-field>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-autocomplete v-model="nomenclatureText" :label="$translate('profile.update.nomenclature')" :items="nomenclature"
                      :item-text="name" :item-value="dian" return-object></v-autocomplete>
                  </v-col>

                  <v-col class="pt-2 pb-2" cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-text-field v-model="infoAdditional" :label="$translate('profile.update.additionalInformation')" ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined style="border-radius: 15px; text-align: initial" class="py-3 px-3">
                      <!-- <v-row> -->
                      <template v-for="data in letters">
                        <!-- <v-col :key="data" cols="1"> -->
                        <v-btn color="#466be3" dark :outlined="data == 'LIMPIAR' || data ==  'AGREGAR' ? false : true " small class="mx-2 my-2" @click="addAddress({ text: data })" :key="data">
                          <strong v-if="data == 'LIMPIAR' || data == 'AGREGAR' "> {{ $translate(`profile.update.${data}`) }} </strong>
                          <strong v-else >    {{  data  }}</strong>
                        </v-btn>
                        <!-- </v-col> -->
                      </template>
                      <!-- </v-row> -->
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-layout justify-end>
                      <v-btn :loading="loadingAll" @click="updateProfile" class="btn-main my-5" dark rounded block>
                        <strong> {{$translate('profile.update.editInformation')}} </strong>
                      </v-btn>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End editar perfil  -->

    <!-- modal de mensajes compartidos -->
    <v-dialog v-model="modal.sms" max-width="430">
      <v-card>
        <v-card-title class="txt-card-text-error">
          <v-row>
            <v-col cols="11">
              {{ messageAlert.title }}
            </v-col>
            <v-col class="pl-0 pr-5" cols="1">
              <v-btn small icon fab @click="modal.sms = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          {{ messageAlert.sms }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="instruction" max-width="600">
      <v-card>
        <v-card-text>
          <video class="mt-3 mb-2" width="500" ref="videoA" id="videoAuto" src="../../assets/Video_2.mp4" controls
            autoplay muted loop />
          <v-btn color="#3957B9" outlined @click="instruction = false">
            cerrar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="instruction" max-width="600">
      <v-card>
        <v-card-text>
          <video class="mt-3 mb-2" width="500" ref="videoA" id="videoAuto" src="../../assets/Video_2.mp4" controls
            autoplay muted loop />
          <v-btn color="#3957B9" outlined @click="instruction = false">
            cerrar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fin de modal -->

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
// import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    moment,
    Alert,
  },
  data() {
    return {
      instruction: false,
      tabs: null,
      text: "",
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      listCivilStatus: [
        {
          name: "Soltero(a)",
        },
        {
          name: "Casado(a)",
        },
        {
          name: "Unión Libre",
        },
        {
          name: "Separado(a)",
        },
        {
          name: "Divorciado(a)",
        },
        {
          name: "ND",
        },
      ],
      listLevel: [
        { name: "Secundaria" },
        { name: "Bachiller" },
        { name: "Técnico" },
        { name: "Tecnología" },
        { name: "Universitario" },
        { name: "Especializacion" },
        { name: "Maestria" },
        { name: "Especializacion" },
        { name: "Doctorado" },
        { name: "Otros" },
        { name: "ND" },
      ],
      listDocumentType: [
        "Cedula de Ciudadania",
        "Tarjeta de Identidad",
        "Cedula de Extranjeria",
        "Permiso especial de permanencia",
        "Permiso de protección temporal",
      ],
      listGender: ["masculino", "femenino"],
      listCities: [
        { name: "Abejorral" },
        { name: "Abrego" },
        { name: "Abriaquí" },
        { name: "Acacias" },
        { name: "Acandí" },
        { name: "Acevedo" },
        { name: "Achí" },
        { name: "Agrado" },
        { name: "Aguachica" },
        { name: "Aguada" },
        { name: "Aguadas" },
        { name: "Agua de Dios" },
        { name: "Aguazul" },
        { name: "Agustín Codazzi" },
        { name: "Aipe" },
        { name: "Albán" },
        { name: "Albán" },
        { name: "Albania" },
        { name: "Albania" },
        { name: "Albania" },
        { name: "Alcalá" },
        { name: "Aldana" },
        { name: "Alejandría" },
        { name: "Algarrobo" },
        { name: "Algeciras" },
        { name: "Almaguer" },
        { name: "Almeida" },
        { name: "Alpujarra" },
        { name: "Altamira" },
        { name: "Alto Baudo" },
        { name: "Altos del Rosario" },
        { name: "Alvarado" },
        { name: "Amagá" },
        { name: "Amalfi" },
        { name: "Ambalema" },
        { name: "Anapoima" },
        { name: "Ancuyá" },
        { name: "Andalucía" },
        { name: "Andes" },
        { name: "Angelópolis" },
        { name: "Angostura" },
        { name: "Anolaima" },
        { name: "Anorí" },
        { name: "Anserma" },
        { name: "Ansermanuevo" },
        { name: "Anza" },
        { name: "Anzoátegui" },
        { name: "Apartadó" },
        { name: "Apía" },
        { name: "Apulo" },
        { name: "Aquitania" },
        { name: "Aracataca" },
        { name: "Aranzazu" },
        { name: "Aratoca" },
        { name: "Arauca" },
        { name: "Arauquita" },
        { name: "Arbeláez" },
        { name: "Arboleda" },
        { name: "Arboledas" },
        { name: "Arboletes" },
        { name: "Arcabuco" },
        { name: "Arenal" },
        { name: "Argelia" },
        { name: "Argelia" },
        { name: "Argelia" },
        { name: "Ariguaní" },
        { name: "Arjona" },
        { name: "Armenia" },
        { name: "Armenia" },
        { name: "Armero" },
        { name: "Arroyohondo" },
        { name: "Astrea" },
        { name: "Ataco" },
        { name: "Atrato" },
        { name: "Ayapel" },
        { name: "Bagadó" },
        { name: "Bahía Solano" },
        { name: "Bajo Baudó" },
        { name: "Balboa" },
        { name: "Balboa" },
        { name: "Baranoa" },
        { name: "Baraya" },
        { name: "Barbacoas" },
        { name: "Barbosa" },
        { name: "Barbosa" },
        { name: "Barichara" },
        { name: "Barrancabermeja" },
        { name: "Barranca de Upía" },
        { name: "Barrancas" },
        { name: "Barranco de Loba" },
        { name: "Barranco Minas" },
        { name: "Barranquilla" },
        { name: "Becerril" },
        { name: "Belalcázar" },
        { name: "Belén" },
        { name: "Belén" },
        { name: "Belén de Bajira" },
        { name: "Belén de Los Andaquies" },
        { name: "Belén de Umbría" },
        { name: "Bello" },
        { name: "Belmira" },
        { name: "Beltrán" },
        { name: "Berbeo" },
        { name: "Betania" },
        { name: "Betéitiva" },
        { name: "Betulia" },
        { name: "Betulia" },
        { name: "Bituima" },
        { name: "Boavita" },
        { name: "Bochalema" },
        { name: "Bogotá D.C." },
        { name: "Bojacá" },
        { name: "Bojaya" },
        { name: "Bolívar" },
        { name: "Bolívar" },
        { name: "Bolívar" },
        { name: "Bosconia" },
        { name: "Boyacá" },
        { name: "Briceño" },
        { name: "Briceño" },
        { name: "Bucaramanga" },
        { name: "Bucarasica" },
        { name: "Buenaventura" },
        { name: "Buenavista" },
        { name: "Buenavista" },
        { name: "Buenavista" },
        { name: "Buena Vista" },
        { name: "Buenos Aires" },
        { name: "Buesaco" },
        { name: "Bugalagrande" },
        { name: "Buriticá" },
        { name: "Busbanzá" },
        { name: "Cabrera" },
        { name: "Cabrera" },
        { name: "Cabuyaro" },
        { name: "Cacahual" },
        { name: "Cáceres" },
        { name: "Cachipay" },
        { name: "Cachirá" },
        { name: "Cácota" },
        { name: "Caicedo" },
        { name: "Caicedonia" },
        { name: "Caimito" },
        { name: "Cajamarca" },
        { name: "Cajibío" },
        { name: "Cajicá" },
        { name: "Calamar" },
        { name: "Calamar" },
        { name: "Calarcá" },
        { name: "Caldas" },
        { name: "Caldas" },
        { name: "Caldono" },
        { name: "Cali" },
        { name: "California" },
        { name: "Calima" },
        { name: "Caloto" },
        { name: "Campamento" },
        { name: "Campoalegre" },
        { name: "Campo de La Cruz" },
        { name: "Campohermoso" },
        { name: "Canalete" },
        { name: "Cañasgordas" },
        { name: "Candelaria" },
        { name: "Candelaria" },
        { name: "Cantagallo" },
        { name: "Caparrapí" },
        { name: "Capitanejo" },
        { name: "Caqueza" },
        { name: "Caracolí" },
        { name: "Caramanta" },
        { name: "Carcasí" },
        { name: "Carepa" },
        { name: "Carmen de Apicala" },
        { name: "Carmen de Carupa" },
        { name: "Carmen del Darien" },
        { name: "Carolina" },
        { name: "Cartagena" },
        { name: "Cartagena del Chairá" },
        { name: "Cartago" },
        { name: "Carurú" },
        { name: "Casabianca" },
        { name: "Castilla la Nueva" },
        { name: "Caucasia" },
        { name: "Cepitá" },
        { name: "Cereté" },
        { name: "Cerinza" },
        { name: "Cerrito" },
        { name: "Cerro San Antonio" },
        { name: "Cértegui" },
        { name: "Chachagüí" },
        { name: "Chaguaní" },
        { name: "Chalán" },
        { name: "Chámeza" },
        { name: "Chaparral" },
        { name: "Charalá" },
        { name: "Charta" },
        { name: "Chía" },
        { name: "Chigorodó" },
        { name: "Chimá" },
        { name: "Chimá" },
        { name: "Chimichagua" },
        { name: "Chinácota" },
        { name: "Chinavita" },
        { name: "Chinchiná" },
        { name: "Chinú" },
        { name: "Chipaque" },
        { name: "Chipatá" },
        { name: "Chiquinquirá" },
        { name: "Chíquiza" },
        { name: "Chiriguaná" },
        { name: "Chiscas" },
        { name: "Chita" },
        { name: "Chitagá" },
        { name: "Chitaraque" },
        { name: "Chivatá" },
        { name: "Chivolo" },
        { name: "Chivor" },
        { name: "Choachí" },
        { name: "Chocontá" },
        { name: "Cicuco" },
        { name: "Ciénaga" },
        { name: "Ciénaga de Oro" },
        { name: "Ciénega" },
        { name: "Cimitarra" },
        { name: "Circasia" },
        { name: "Cisneros" },
        { name: "Ciudad Bolívar" },
        { name: "Clemencia" },
        { name: "Cocorná" },
        { name: "Coello" },
        { name: "Cogua" },
        { name: "Colombia" },
        { name: "Colón" },
        { name: "Colón" },
        { name: "Coloso" },
        { name: "Cómbita" },
        { name: "Concepción" },
        { name: "Concepción" },
        { name: "Concordia" },
        { name: "Concordia" },
        { name: "Condoto" },
        { name: "Confines" },
        { name: "Consaca" },
        { name: "Contadero" },
        { name: "Contratación" },
        { name: "Convención" },
        { name: "Copacabana" },
        { name: "Coper" },
        { name: "Córdoba" },
        { name: "Córdoba" },
        { name: "Córdoba" },
        { name: "Corinto" },
        { name: "Coromoro" },
        { name: "Corozal" },
        { name: "Corrales" },
        { name: "Cota" },
        { name: "Cotorra" },
        { name: "Covarachía" },
        { name: "Coveñas" },
        { name: "Coyaima" },
        { name: "Cravo Norte" },
        { name: "Cuaspud" },
        { name: "Cubará" },
        { name: "Cubarral" },
        { name: "Cucaita" },
        { name: "Cucunubá" },
        { name: "Cúcuta" },
        { name: "Cucutilla" },
        { name: "Cuítiva" },
        { name: "Cumaral" },
        { name: "Cumaribo" },
        { name: "Cumbal" },
        { name: "Cumbitara" },
        { name: "Cunday" },
        { name: "Curillo" },
        { name: "Curití" },
        { name: "Curumaní" },
        { name: "Dabeiba" },
        { name: "Dagua" },
        { name: "Dibula" },
        { name: "Distracción" },
        { name: "Dolores" },
        { name: "Don Matías" },
        { name: "Dosquebradas" },
        { name: "Duitama" },
        { name: "Durania" },
        { name: "Ebéjico" },
        { name: "El Águila" },
        { name: "El Bagre" },
        { name: "El Banco" },
        { name: "El Cairo" },
        { name: "El Calvario" },
        { name: "El Cantón del San Pablo" },
        { name: "El Carmen" },
        { name: "El Carmen de Atrato" },
        { name: "El Carmen de Bolívar" },
        { name: "El Carmen de Chucurí" },
        { name: "El Carmen de Viboral" },
        { name: "El Castillo" },
        { name: "El Cerrito" },
        { name: "El Charco" },
        { name: "El Cocuy" },
        { name: "El Colegio" },
        { name: "El Copey" },
        { name: "El Doncello" },
        { name: "El Dorado" },
        { name: "El Dovio" },
        { name: "El Encanto" },
        { name: "El Espino" },
        { name: "El Guacamayo" },
        { name: "El Guamo" },
        { name: "Elías" },
        { name: "El Litoral del San Juan" },
        { name: "El Molino" },
        { name: "El Paso" },
        { name: "El Paujil" },
        { name: "El Peñol" },
        { name: "El Peñón" },
        { name: "El Peñón" },
        { name: "El Peñón" },
        { name: "El Piñon" },
        { name: "El Playón" },
        { name: "El Retén" },
        { name: "El Retorno" },
        { name: "El Roble" },
        { name: "El Rosal" },
        { name: "El Rosario" },
        { name: "El Santuario" },
        { name: "El Tablón de Gómez" },
        { name: "El Tambo" },
        { name: "El Tambo" },
        { name: "El Tarra" },
        { name: "El Zulia" },
        { name: "Encino" },
        { name: "Enciso" },
        { name: "Entrerrios" },
        { name: "Envigado" },
        { name: "Espinal" },
        { name: "Facatativá" },
        { name: "Falan" },
        { name: "Filadelfia" },
        { name: "Filandia" },
        { name: "Firavitoba" },
        { name: "Flandes" },
        { name: "Florencia" },
        { name: "Florencia" },
        { name: "Floresta" },
        { name: "Florián" },
        { name: "Florida" },
        { name: "Floridablanca" },
        { name: "Fomeque" },
        { name: "Fonseca" },
        { name: "Fortul" },
        { name: "Fosca" },
        { name: "Francisco Pizarro" },
        { name: "Fredonia" },
        { name: "Fresno" },
        { name: "Frontino" },
        { name: "Fuente de Oro" },
        { name: "Fundación" },
        { name: "Funes" },
        { name: "Funza" },
        { name: "Fúquene" },
        { name: "Fusagasugá" },
        { name: "Gachala" },
        { name: "Gachancipá" },
        { name: "Gachantivá" },
        { name: "Gachetá" },
        { name: "Galán" },
        { name: "Galapa" },
        { name: "Galeras" },
        { name: "Gama" },
        { name: "Gamarra" },
        { name: "Gambita" },
        { name: "Gameza" },
        { name: "Garagoa" },
        { name: "Garzón" },
        { name: "Génova" },
        { name: "Gigante" },
        { name: "Ginebra" },
        { name: "Giraldo" },
        { name: "Girardot" },
        { name: "Girardota" },
        { name: "Girón" },
        { name: "Gómez Plata" },
        { name: "González" },
        { name: "Gramalote" },
        { name: "Granada" },
        { name: "Granada" },
        { name: "Granada" },
        { name: "Guaca" },
        { name: "Guacamayas" },
        { name: "Guacarí" },
        { name: "Guachené" },
        { name: "Guachetá" },
        { name: "Guachucal" },
        { name: "Guadalajara de Buga" },
        { name: "Guadalupe" },
        { name: "Guadalupe" },
        { name: "Guadalupe" },
        { name: "Guaduas" },
        { name: "Guaitarilla" },
        { name: "Gualmatán" },
        { name: "Guamal" },
        { name: "Guamal" },
        { name: "Guamo" },
        { name: "Guapi" },
        { name: "Guapotá" },
        { name: "Guaranda" },
        { name: "Guarne" },
        { name: "Guasca" },
        { name: "Guatapé" },
        { name: "Guataquí" },
        { name: "Guatavita" },
        { name: "Guateque" },
        { name: "Guática" },
        { name: "Guavatá" },
        { name: "Guayabal de Siquima" },
        { name: "Guayabetal" },
        { name: "Guayatá" },
        { name: "Güepsa" },
        { name: "Güicán" },
        { name: "Gutiérrez" },
        { name: "Hacarí" },
        { name: "Hatillo de Loba" },
        { name: "Hato" },
        { name: "Hato Corozal" },
        { name: "Hatonuevo" },
        { name: "Heliconia" },
        { name: "Herrán" },
        { name: "Herveo" },
        { name: "Hispania" },
        { name: "Hobo" },
        { name: "Honda" },
        { name: "Ibagué" },
        { name: "Icononzo" },
        { name: "Iles" },
        { name: "Imués" },
        { name: "Inírida" },
        { name: "Inzá" },
        { name: "Ipiales" },
        { name: "Iquira" },
        { name: "Isnos" },
        { name: "Istmina" },
        { name: "Itagui" },
        { name: "Ituango" },
        { name: "Iza" },
        { name: "Jambaló" },
        { name: "Jamundí" },
        { name: "Jardín" },
        { name: "Jenesano" },
        { name: "Jericó" },
        { name: "Jericó" },
        { name: "Jerusalén" },
        { name: "Jesús María" },
        { name: "Jordán" },
        { name: "Juan de Acosta" },
        { name: "Junín" },
        { name: "Juradó" },
        { name: "La Apartada" },
        { name: "La Argentina" },
        { name: "Labateca" },
        { name: "La Belleza" },
        { name: "Labranzagrande" },
        { name: "La Calera" },
        { name: "La Capilla" },
        { name: "La Ceja" },
        { name: "La Celia" },
        { name: "La Chorrera" },
        { name: "La Cruz" },
        { name: "La Cumbre" },
        { name: "La Dorada" },
        { name: "La Esperanza" },
        { name: "La Estrella" },
        { name: "La Florida" },
        { name: "La Gloria" },
        { name: "La Guadalupe" },
        { name: "La Jagua de Ibirico" },
        { name: "La Jagua del Pilar" },
        { name: "La Llanada" },
        { name: "La Macarena" },
        { name: "La Merced" },
        { name: "La Mesa" },
        { name: "La Montañita" },
        { name: "Landázuri" },
        { name: "La Palma" },
        { name: "La Paz" },
        { name: "La Paz" },
        { name: "La Pedrera" },
        { name: "La Peña" },
        { name: "La Pintada" },
        { name: "La Plata" },
        { name: "La Playa" },
        { name: "La Primavera" },
        { name: "La Salina" },
        { name: "La Sierra" },
        { name: "La Tebaida" },
        { name: "La Tola" },
        { name: "La Unión" },
        { name: "La Unión" },
        { name: "La Unión" },
        { name: "La Unión" },
        { name: "La Uvita" },
        { name: "La Vega" },
        { name: "La Vega" },
        { name: "La Victoria" },
        { name: "La Victoria" },
        { name: "La Victoria" },
        { name: "La Virginia" },
        { name: "Lebríja" },
        { name: "Leguízamo" },
        { name: "Leiva" },
        { name: "Lejanías" },
        { name: "Lenguazaque" },
        { name: "Lérida" },
        { name: "Leticia" },
        { name: "Líbano" },
        { name: "Liborina" },
        { name: "Linares" },
        { name: "Lloró" },
        { name: "López" },
        { name: "Lorica" },
        { name: "Los Andes" },
        { name: "Los Córdobas" },
        { name: "Los Palmitos" },
        { name: "Los Patios" },
        { name: "Los Santos" },
        { name: "Lourdes" },
        { name: "Luruaco" },
        { name: "Macanal" },
        { name: "Macaravita" },
        { name: "Maceo" },
        { name: "Macheta" },
        { name: "Madrid" },
        { name: "Magangué" },
        { name: "Magüí" },
        { name: "Mahates" },
        { name: "Maicao" },
        { name: "Majagual" },
        { name: "Málaga" },
        { name: "Malambo" },
        { name: "Mallama" },
        { name: "Manatí" },
        { name: "Manaure" },
        { name: "Manaure" },
        { name: "Maní" },
        { name: "Manizales" },
        { name: "Manta" },
        { name: "Manzanares" },
        { name: "Mapiripán" },
        { name: "Mapiripana" },
        { name: "Margarita" },
        { name: "María la Baja" },
        { name: "Marinilla" },
        { name: "Maripí" },
        { name: "Mariquita" },
        { name: "Marmato" },
        { name: "Marquetalia" },
        { name: "Marsella" },
        { name: "Marulanda" },
        { name: "Matanza" },
        { name: "Medellín" },
        { name: "Medina" },
        { name: "Medio Atrato" },
        { name: "Medio Baudó" },
        { name: "Medio San Juan" },
        { name: "Melgar" },
        { name: "Mercaderes" },
        { name: "Mesetas" },
        { name: "Milán" },
        { name: "Miraflores" },
        { name: "Miraflores" },
        { name: "Miranda" },
        { name: "Miriti Paraná" },
        { name: "Mistrató" },
        { name: "Mitú" },
        { name: "Mocoa" },
        { name: "Mogotes" },
        { name: "Molagavita" },
        { name: "Momil" },
        { name: "Mompós" },
        { name: "Mongua" },
        { name: "Monguí" },
        { name: "Moniquirá" },
        { name: "Moñitos" },
        { name: "Montebello" },
        { name: "Montecristo" },
        { name: "Montelíbano" },
        { name: "Montenegro" },
        { name: "Montería" },
        { name: "Monterrey" },
        { name: "Morales" },
        { name: "Morales" },
        { name: "Morelia" },
        { name: "Morichal" },
        { name: "Morroa" },
        { name: "Mosquera" },
        { name: "Mosquera" },
        { name: "Motavita" },
        { name: "Murillo" },
        { name: "Murindó" },
        { name: "Mutatá" },
        { name: "Mutiscua" },
        { name: "Muzo" },
        { name: "Nariño" },
        { name: "Nariño" },
        { name: "Nariño" },
        { name: "Nátaga" },
        { name: "Natagaima" },
        { name: "Nechí" },
        { name: "Necoclí" },
        { name: "Neira" },
        { name: "Neiva" },
        { name: "Nemocón" },
        { name: "Nilo" },
        { name: "Nimaima" },
        { name: "Nobsa" },
        { name: "Nocaima" },
        { name: "Norcasia" },
        { name: "Norosí" },
        { name: "Nóvita" },
        { name: "Nueva Granada" },
        { name: "Nuevo Colón" },
        { name: "Nunchía" },
        { name: "Nuquí" },
        { name: "Obando" },
        { name: "Ocamonte" },
        { name: "Ocaña" },
        { name: "Oiba" },
        { name: "Oicatá" },
        { name: "Olaya" },
        { name: "Olaya Herrera" },
        { name: "Onzaga" },
        { name: "Oporapa" },
        { name: "Orito" },
        { name: "Orocué" },
        { name: "Ortega" },
        { name: "Ospina" },
        { name: "Otanche" },
        { name: "Ovejas" },
        { name: "Pachavita" },
        { name: "Pacho" },
        { name: "Pacoa" },
        { name: "Pácora" },
        { name: "Padilla" },
        { name: "Páez" },
        { name: "Páez" },
        { name: "Paicol" },
        { name: "Pailitas" },
        { name: "Paime" },
        { name: "Paipa" },
        { name: "Pajarito" },
        { name: "Palermo" },
        { name: "Palestina" },
        { name: "Palestina" },
        { name: "Palmar" },
        { name: "Palmar de Varela" },
        { name: "Palmas del Socorro" },
        { name: "Palmira" },
        { name: "Palmito" },
        { name: "Palocabildo" },
        { name: "Pamplona" },
        { name: "Pamplonita" },
        { name: "Pana Pana" },
        { name: "Pandi" },
        { name: "Panqueba" },
        { name: "Papunahua" },
        { name: "Páramo" },
        { name: "Paratebueno" },
        { name: "Pasca" },
        { name: "Pasto" },
        { name: "Patía" },
        { name: "Pauna" },
        { name: "Paya" },
        { name: "Paz de Ariporo" },
        { name: "Paz de Río" },
        { name: "Pedraza" },
        { name: "Pelaya" },
        { name: "Peñol" },
        { name: "Pensilvania" },
        { name: "Peque" },
        { name: "Pereira" },
        { name: "Pesca" },
        { name: "Piamonte" },
        { name: "Piedecuesta" },
        { name: "Piedras" },
        { name: "Piendamó" },
        { name: "Pijao" },
        { name: "Pijiño del Carmen" },
        { name: "Pinchote" },
        { name: "Pinillos" },
        { name: "Piojó" },
        { name: "Pisba" },
        { name: "Pital" },
        { name: "Pitalito" },
        { name: "Pivijay" },
        { name: "Planadas" },
        { name: "Planeta Rica" },
        { name: "Plato" },
        { name: "Policarpa" },
        { name: "Polonuevo" },
        { name: "Ponedera" },
        { name: "Popayán" },
        { name: "Pore" },
        { name: "Potosí" },
        { name: "Pradera" },
        { name: "Prado" },
        { name: "Providencia" },
        { name: "Providencia" },
        { name: "Pueblo Bello" },
        { name: "Pueblo Nuevo" },
        { name: "Pueblo Rico" },
        { name: "Pueblorrico" },
        { name: "Pueblo Viejo" },
        { name: "Puente Nacional" },
        { name: "Puerres" },
        { name: "Puerto Alegría" },
        { name: "Puerto Arica" },
        { name: "Puerto Asís" },
        { name: "Puerto Berrío" },
        { name: "Puerto Boyacá" },
        { name: "Puerto Caicedo" },
        { name: "Puerto Carreño" },
        { name: "Puerto Colombia" },
        { name: "Puerto Colombia" },
        { name: "Puerto Concordia" },
        { name: "Puerto Escondido" },
        { name: "Puerto Gaitán" },
        { name: "Puerto Guzmán" },
        { name: "Puerto Libertador" },
        { name: "Puerto Lleras" },
        { name: "Puerto López" },
        { name: "Puerto Nare" },
        { name: "Puerto Nariño" },
        { name: "Puerto Parra" },
        { name: "Puerto Rico" },
        { name: "Puerto Rico" },
        { name: "Puerto Rondón" },
        { name: "Puerto Salgar" },
        { name: "Puerto Santander" },
        { name: "Puerto Santander" },
        { name: "Puerto Tejada" },
        { name: "Puerto Triunfo" },
        { name: "Puerto Wilches" },
        { name: "Pulí" },
        { name: "Pupiales" },
        { name: "Puracé" },
        { name: "Purificación" },
        { name: "Purísima" },
        { name: "Quebradanegra" },
        { name: "Quetame" },
        { name: "Quibdó" },
        { name: "Quimbaya" },
        { name: "Quinchía" },
        { name: "Quípama" },
        { name: "Quipile" },
        { name: "Ragonvalia" },
        { name: "Ramiriquí" },
        { name: "Ráquira" },
        { name: "Recetor" },
        { name: "Regidor" },
        { name: "Remedios" },
        { name: "Remolino" },
        { name: "Repelón" },
        { name: "Restrepo" },
        { name: "Restrepo" },
        { name: "Retiro" },
        { name: "Ricaurte" },
        { name: "Ricaurte" },
        { name: "Rio Blanco" },
        { name: "Río de Oro" },
        { name: "Riofrío" },
        { name: "Riohacha" },
        { name: "Río Iro" },
        { name: "Rionegro" },
        { name: "Rionegro" },
        { name: "Río Quito" },
        { name: "Riosucio" },
        { name: "Riosucio" },
        { name: "Río Viejo" },
        { name: "Risaralda" },
        { name: "Rivera" },
        { name: "Roberto Payán" },
        { name: "Roldanillo" },
        { name: "Roncesvalles" },
        { name: "Rondón" },
        { name: "Rosas" },
        { name: "Rovira" },
        { name: "Sabana de Torres" },
        { name: "Sabanagrande" },
        { name: "Sabanalarga" },
        { name: "Sabanalarga" },
        { name: "Sabanalarga" },
        { name: "Sabanas de San Angel" },
        { name: "Sabaneta" },
        { name: "Saboyá" },
        { name: "Sácama" },
        { name: "Sáchica" },
        { name: "Sahagún" },
        { name: "Saladoblanco" },
        { name: "Salamina" },
        { name: "Salamina" },
        { name: "Salazar" },
        { name: "Saldaña" },
        { name: "Salento" },
        { name: "Salgar" },
        { name: "Samacá" },
        { name: "Samaná" },
        { name: "Samaniego" },
        { name: "Sampués" },
        { name: "San Agustín" },
        { name: "San Alberto" },
        { name: "San Andrés" },
        { name: "San Andrés" },
        { name: "San Andrés de Cuerquía" },
        { name: "San Andrés de Tumaco" },
        { name: "San Andrés Sotavento" },
        { name: "San Antero" },
        { name: "San Antonio" },
        { name: "San Antonio del Tequendama" },
        { name: "San Benito" },
        { name: "San Benito Abad" },
        { name: "San Bernardo" },
        { name: "San Bernardo" },
        { name: "San Bernardo del Viento" },
        { name: "San Calixto" },
        { name: "San Carlos" },
        { name: "San Carlos" },
        { name: "San Carlos de Guaroa" },
        { name: "San Cayetano" },
        { name: "San Cayetano" },
        { name: "San Cristóbal" },
        { name: "San Diego" },
        { name: "Sandoná" },
        { name: "San Eduardo" },
        { name: "San Estanislao" },
        { name: "San Felipe" },
        { name: "San Fernando" },
        { name: "San Francisco" },
        { name: "San Francisco" },
        { name: "San Francisco" },
        { name: "San Gil" },
        { name: "San Jacinto" },
        { name: "San Jacinto del Cauca" },
        { name: "San Jerónimo" },
        { name: "San Joaquín" },
        { name: "San José" },
        { name: "San José de La Montaña" },
        { name: "San José del Fragua" },
        { name: "San José del Guaviare" },
        { name: "San José del Palmar" },
        { name: "San José de Miranda" },
        { name: "San José de Pare" },
        { name: "San José de Uré" },
        { name: "San Juan de Arama" },
        { name: "San Juan de Betulia" },
        { name: "San Juan del Cesar" },
        { name: "San Juan de Río Seco" },
        { name: "San Juan de Urabá" },
        { name: "San Juanito" },
        { name: "San Juan Nepomuceno" },
        { name: "San Lorenzo" },
        { name: "San Luis" },
        { name: "San Luis" },
        { name: "San Luis de Gaceno" },
        { name: "San Luis de Gaceno" },
        { name: "San Luis de Sincé" },
        { name: "San Marcos" },
        { name: "San Martín" },
        { name: "San Martín" },
        { name: "San Martín de Loba" },
        { name: "San Mateo" },
        { name: "San Miguel" },
        { name: "San Miguel" },
        { name: "San Miguel de Sema" },
        { name: "San Onofre" },
        { name: "San Pablo" },
        { name: "San Pablo de Borbur" },
        { name: "San Pablo de Borbur" },
        { name: "San Pedro" },
        { name: "San Pedro" },
        { name: "San Pedro" },
        { name: "San Pedro de Cartago" },
        { name: "San Pedro de Uraba" },
        { name: "San Pelayo" },
        { name: "San Rafael" },
        { name: "San Roque" },
        { name: "San Sebastián" },
        { name: "San Sebastián de Buenavista" },
        { name: "Santa Ana" },
        { name: "Santa Bárbara" },
        { name: "Santa Bárbara" },
        { name: "Santa Bárbara" },
        { name: "Santa Bárbara de Pinto" },
        { name: "Santa Catalina" },
        { name: "Santacruz" },
        { name: "Santafé de Antioquia" },
        { name: "Santa Helena del Opón" },
        { name: "Santa Isabel" },
        { name: "Santa Lucía" },
        { name: "Santa María" },
        { name: "Santa María" },
        { name: "Santa Marta" },
        { name: "Santana" },
        { name: "Santander de Quilichao" },
        { name: "Santa Rosa" },
        { name: "Santa Rosa" },
        { name: "Santa Rosa de Cabal" },
        { name: "Santa Rosa del Sur" },
        { name: "Santa Rosa de Osos" },
        { name: "Santa Rosa de Viterbo" },
        { name: "Santa Rosalía" },
        { name: "Santa Sofía" },
        { name: "Santiago" },
        { name: "Santiago" },
        { name: "Santiago de Tolú" },
        { name: "Santo Domingo" },
        { name: "Santo Tomás" },
        { name: "Santuario" },
        { name: "San Vicente" },
        { name: "San Vicente de Chucurí" },
        { name: "San Vicente del Caguán" },
        { name: "San Zenón" },
        { name: "Sapuyes" },
        { name: "Saravena" },
        { name: "Sardinata" },
        { name: "Sasaima" },
        { name: "Sativanorte" },
        { name: "Sativasur" },
        { name: "Segovia" },
        { name: "Sesquilé" },
        { name: "Sevilla" },
        { name: "Siachoque" },
        { name: "Sibaté" },
        { name: "Sibundoy" },
        { name: "Silos" },
        { name: "Silvania" },
        { name: "Silvia" },
        { name: "Simacota" },
        { name: "Simijaca" },
        { name: "Simití" },
        { name: "Sincelejo" },
        { name: "Sipí" },
        { name: "Sitionuevo" },
        { name: "Soacha" },
        { name: "Soatá" },
        { name: "Socha" },
        { name: "Socorro" },
        { name: "Socotá" },
        { name: "Sogamoso" },
        { name: "Solano" },
        { name: "Soledad" },
        { name: "Solita" },
        { name: "Somondoco" },
        { name: "Sonsón" },
        { name: "Sopetrán" },
        { name: "Soplaviento" },
        { name: "Sopó" },
        { name: "Sora" },
        { name: "Soracá" },
        { name: "Sotaquirá" },
        { name: "Sotara" },
        { name: "Suaita" },
        { name: "Suan" },
        { name: "Suárez" },
        { name: "Suárez" },
        { name: "Suaza" },
        { name: "Subachoque" },
        { name: "Sucre" },
        { name: "Sucre" },
        { name: "Sucre" },
        { name: "Suesca" },
        { name: "Supatá" },
        { name: "Supía" },
        { name: "Suratá" },
        { name: "Susa" },
        { name: "Susacón" },
        { name: "Sutamarchán" },
        { name: "Sutatausa" },
        { name: "Sutatenza" },
        { name: "Tabio" },
        { name: "Tadó" },
        { name: "Talaigua Nuevo" },
        { name: "Tamalameque" },
        { name: "Támara" },
        { name: "Tame" },
        { name: "Támesis" },
        { name: "Taminango" },
        { name: "Tangua" },
        { name: "Taraira" },
        { name: "Tarapacá" },
        { name: "Tarazá" },
        { name: "Tarqui" },
        { name: "Tarso" },
        { name: "Tasco" },
        { name: "Tauramena" },
        { name: "Tausa" },
        { name: "Tello" },
        { name: "Tena" },
        { name: "Tenerife" },
        { name: "Tenjo" },
        { name: "Tenza" },
        { name: "Teorama" },
        { name: "Teruel" },
        { name: "Tesalia" },
        { name: "Tibacuy" },
        { name: "Tibaná" },
        { name: "Tibasosa" },
        { name: "Tibirita" },
        { name: "Tibú" },
        { name: "Tierralta" },
        { name: "Timaná" },
        { name: "Timbío" },
        { name: "Timbiquí" },
        { name: "Tinjacá" },
        { name: "Tipacoque" },
        { name: "Tiquisio" },
        { name: "Titiribí" },
        { name: "Toca" },
        { name: "Tocaima" },
        { name: "Tocancipá" },
        { name: "Togüí" },
        { name: "Toledo" },
        { name: "Toledo" },
        { name: "Tolú Viejo" },
        { name: "Tona" },
        { name: "Tópaga" },
        { name: "Topaipí" },
        { name: "Toribio" },
        { name: "Toro" },
        { name: "Tota" },
        { name: "Totoró" },
        { name: "Trinidad" },
        { name: "Trujillo" },
        { name: "Tubará" },
        { name: "Tuchín" },
        { name: "Tuluá" },
        { name: "Tunja" },
        { name: "Tununguá" },
        { name: "Túquerres" },
        { name: "Turbaco" },
        { name: "Turbaná" },
        { name: "Turbo" },
        { name: "Turmequé" },
        { name: "Tuta" },
        { name: "Tutazá" },
        { name: "Ubalá" },
        { name: "Ubaque" },
        { name: "Ulloa" },
        { name: "Umbita" },
        { name: "Une" },
        { name: "Unguía" },
        { name: "Unión Panamericana" },
        { name: "Uramita" },
        { name: "Uribe" },
        { name: "Uribia" },
        { name: "Urrao" },
        { name: "Urumita" },
        { name: "Usiacurí" },
        { name: "Útica" },
        { name: "Valdivia" },
        { name: "Valencia" },
        { name: "Valle de Guamez" },
        { name: "Valle de San José" },
        { name: "Valle de San Juan" },
        { name: "Valledupar" },
        { name: "Valparaíso" },
        { name: "Valparaíso" },
        { name: "Vegachí" },
        { name: "Vélez" },
        { name: "Venadillo" },
        { name: "Venecia" },
        { name: "Venecia" },
        { name: "Ventaquemada" },
        { name: "Vergara" },
        { name: "Versalles" },
        { name: "Vetas" },
        { name: "Vianí" },
        { name: "Victoria" },
        { name: "Vigía del Fuerte" },
        { name: "Vijes" },
        { name: "Villa Caro" },
        { name: "Villa de Leyva" },
        { name: "Villa del Rosario" },
        { name: "Villa de San Diego de Ubate" },
        { name: "Villagarzón" },
        { name: "Villagómez" },
        { name: "Villahermosa" },
        { name: "Villamaría" },
        { name: "Villanueva" },
        { name: "Villanueva" },
        { name: "Villanueva" },
        { name: "Villanueva" },
        { name: "Villapinzón" },
        { name: "Villa Rica" },
        { name: "Villarrica" },
        { name: "Villavicencio" },
        { name: "Villavieja" },
        { name: "Villeta" },
        { name: "Viotá" },
        { name: "Viracachá" },
        { name: "Vista Hermosa" },
        { name: "Viterbo" },
        { name: "Yacopí" },
        { name: "Yacuanquer" },
        { name: "Yaguará" },
        { name: "Yalí" },
        { name: "Yarumal" },
        { name: "Yavaraté" },
        { name: "Yolombó" },
        { name: "Yondó" },
        { name: "Yopal" },
        { name: "Yotoco" },
        { name: "Yumbo" },
        { name: "Zambrano" },
        { name: "Zapatoca" },
        { name: "Zapayán" },
        { name: "Zaragoza" },
        { name: "Zarzal" },
        { name: "Zetaquira" },
        { name: "Zipacón" },
        { name: "Zipaquirá" },
        { name: "Zona Bananera" },
      ],
      listDepartament: [
        { name: "Amazonas" },
        { name: "Antioquia" },
        { name: "Arauca" },
        { name: "Archipiélago De San Andrés, Providencia" },
        { name: "Atlántico" },
        { name: "Bogotá D.C" },
        { name: "Bolívar" },
        { name: "Boyacá" },
        { name: "Caldas" },
        { name: "Caquetá" },
        { name: "Casanare" },
        { name: "Cauca" },
        { name: "Cesar" },
        { name: "Chocó" },
        { name: "Córdoba" },
        { name: "Cundinamarca" },
        { name: "Guainía" },
        { name: "Guaviare" },
        { name: "Huila" },
        { name: "La Guajira" },
        { name: "Magdalena" },
        { name: "Meta" },
        { name: "Nariño" },
        { name: "Norte De Santander" },
        { name: "Putumayo" },
        { name: "Quindio" },
        { name: "Risaralda" },
        { name: "Santander" },
        { name: "Sucre" },
        { name: "Tolima" },
        { name: "Valle Del Cauca " },
        { name: "Vaupés " },
        { name: "Vichada" },
      ],
      listTypeH: [
        { name: "Propia" },
        { name: "En Arriendo" },
        { name: "Familiar" },
        { name: "Pieza" },
        { name: "ND" },
      ],
      listStatrum: [
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
        { name: "6" },
      ],
      menu2: false,
      menu1: false,
      messageAlert: {
        title: "",
        sms: "",
      },
      formEdit: {
        name: "",
        lastName: "",
        documentExpeditionDate: "",
        birthDate: "",
        email: "",
        personalEmail: "",
        phone: "",
        localPhone: "",
        civilStatus: "",
        address: "",
        addressDian: "",
        documentNumber: "",
        documentType: "",
        stratum: "",
        housingType: "",
        academicLevel: "",
        gender: "",
        residenceNeighborhood: "",
        locality: "",
        residenceDepartament: "",
        residenceCity: "",
        fullName: "",
        age: null,
        eps: null,
        pension: null,
      },
      origin: window.location.origin,
      loadingAll: false,
      modalSend: true,
      modal: {
        edit: false,
        sms: false,
      },
      dialog: false,
      info: {
        profile: {
          name: "",
        },
        status: {
          name: "",
        },
      },
      info_Tec: [],
      id: null,
      img: "",
      infoToken: {
        document: "",
      },
      infoTec: {
        sede: null,
        puesto: null,
        piso: null,
        mac_addres: null,
      },
      infoAdditional: "",
      nomenclatureText: "",
      birthday: null,
      workTime: null,
      changeImg: false,
      changeImg1: false,
      file: null,
      tokenB64: null,
      token: null,
      listActionsPlan: 0,
      listPerformance: 0,
      clientId: null,
      campaignId: null,
      fileFormat: false,
      buttonLoading: false,
      references: [],
      listAudit: [],
      nomenclature: [
        {
          dian: " # ",
          text: " # ",
          info: false,
        },
        {
          dian: " - ",
          text: " - ",
          info: false,
        },
        {
          dian: " AD ",
          text: " Administracion ",
          info: false,
        },
        {
          dian: " AG ",
          text: " Agencia ",
          info: false,
        },
        {
          dian: " AGP ",
          text: " Agrupacion ",
          info: false,
        },
        {
          dian: " ALM ",
          text: " Almacen ",
          info: false,
        },
        {
          dian: " AL ",
          text: " Altillo ",
          info: false,
        },
        {
          dian: " APTDO ",
          text: " Apartado ",
          info: false,
        },
        {
          dian: " AP ",
          text: " Apartamento ",
          info: false,
        },
        {
          dian: " AUT ",
          text: " Autopista ",
          info: false,
        },
        {
          dian: " AV ",
          text: " Avenida ",
          info: false,
        },
        {
          dian: " AK ",
          text: " Avenida carrera ",
          info: false,
        },
        {
          dian: " BRR ",
          text: " Barrio ",
          info: false,
        },
        {
          dian: " BL ",
          text: " Bloque ",
          info: false,
        },
        {
          dian: " BG ",
          text: " Bodega ",
          info: false,
        },
        {
          dian: " BLV ",
          text: " Boulevard ",
          info: false,
        },
        {
          dian: " CL ",
          text: " Calle ",
          info: false,
        },
        {
          dian: " CN ",
          text: " Camino ",
          info: false,
        },
        {
          dian: " CR ",
          text: " Carrera ",
          info: false,
        },
        {
          dian: " CRT ", //
          text: " Carretera ",
          info: false,
        },
        {
          dian: " CA ",
          text: " Casa ",
          info: false,
        },
        {
          dian: " CEL ",
          text: " Celula ",
          info: false,
        },
        {
          dian: " CC ",
          text: " Centro comercial ",
          info: false,
        },
        {
          dian: " CIR ",
          text: " Circular ",
          info: false,
        },
        {
          dian: " CRV ",
          text: " Circunvalar ",
          info: false,
        },
        {
          dian: " CD ",
          text: " Ciudadela ",
          info: false,
        },
        {
          dian: " CONJ ",
          text: " Conjunto ",
          info: false,
        },
        {
          dian: " CR ",
          text: " Conjunto residencial ",
          info: false,
        },
        {
          dian: " CS ",
          text: " Consultorio ",
          info: false,
        },
        {
          dian: " C ",
          text: " Corregimiento ",
          info: false,
        },
        {
          dian: " DPTO ",
          text: " Departamento ",
          info: false,
        },
        {
          dian: " DP ",
          text: " Deposito ",
          info: false,
        },
        {
          dian: " DG ",
          text: " Diagonal ",
          info: false,
        },
        {
          dian: " ED ",
          text: " Edificio ",
          info: false,
        },
        {
          dian: " EN ",
          text: " Entrada ",
          info: false,
        },
        {
          dian: " ESQ ",
          text: " Esquina ",
          info: false,
        },
        {
          dian: " ESTE ",
          text: " Este ",
          info: false,
        },
        {
          dian: " ET ",
          text: " Etapa ",
          info: false,
        },
        {
          dian: " EX ",
          text: " Exterior ",
          info: false,
        },
        {
          dian: " FCA ",
          text: " Finca ",
          info: false,
        },
        {
          dian: " GJ ",
          text: " Garaje ",
          info: false,
        },

        {
          dian: " GS ",
          text: " Garaje sotano ",
          info: false,
        },
        {
          dian: " HC ",
          text: " Hacienda ",
          info: false,
        },
        {
          dian: " IN ",
          text: " Interior ",
          info: false,
        },
        {
          dian: " KM ",
          text: " Kilometro ",
          info: false,
        },
        {
          dian: " LC ",
          text: " Local ",
          info: false,
        },
        {
          dian: " LM ",
          text: " Local mezzanine ",
          info: false,
        },
        {
          dian: " LT ",
          text: " Lote ",
          info: false,
        },
        {
          dian: " MZ ",
          text: " Manzana ",
          info: false,
        },
        {
          dian: " MN ",
          text: " Mazzanine ",
          info: false,
        },
        {
          dian: " MD ",
          text: " Modulo ",
          info: false,
        },
        {
          dian: " MCP ",
          text: " Municipio ",
          info: false,
        },
        {
          dian: " NORTE ",
          text: " Norte ",
          info: false,
        },
        {
          dian: " OCC ",
          text: " Occidente ",
          info: false,
        },
        {
          dian: " OESTE ",
          text: " Oeste ",
          info: false,
        },
        {
          dian: " OF ",
          text: " Oficina ",
          info: false,
        },
        {
          dian: " O ",
          text: " Oriente ",
          info: false,
        },
        {
          dian: " PA ",
          text: " Parcela ",
          info: false,
        },
        {
          dian: " PAR ",
          text: " Parque ",
          info: false,
        },
        {
          dian: " PQ ",
          text: " Parqueadero ",
          info: false,
        },
        {
          dian: " PJ ",
          text: " Pasaje ",
          info: false,
        },
        {
          dian: " PH ",
          text: " Penthouse ",
          info: false,
        },
        {
          dian: " P ",
          text: " Piso ",
          info: false,
        },
        {
          dian: " PL ",
          text: " Planta ",
          info: false,
        },
        {
          dian: " POR ",
          text: " Porteria ",
          info: false,
        },
        {
          dian: " PD ",
          text: " Predio ",
          info: false,
        },
        {
          dian: " PN ",
          text: " Puente ",
          info: false,
        },
        {
          dian: " PT ",
          text: " Puesto ",
          info: false,
        },
        {
          dian: " SA ",
          text: " Salon ",
          info: false,
        },
        {
          dian: " SC ",
          text: " Salon comunal ",
          info: false,
        },
        {
          dian: " SEC ",
          text: " Sector ",
          info: false,
        },
        {
          dian: " SS ",
          text: " Semisotano ",
          info: false,
        },
        {
          dian: " SL ",
          text: " Solar ",
          info: false,
        },
        {
          dian: " ST ",
          text: " Sotano ",
          info: false,
        },
        {
          dian: " SUITE ",
          text: " Suite ",
          info: false,
        },
        {
          dian: " SM ",
          text: " Super manzana ",
          info: false,
        },
        {
          dian: " SUR ",
          text: " Sur ",
          info: false,
        },
        {
          dian: " TER ",
          text: " Terminal ",
          info: false,
        },
        {
          dian: " TZ ",
          text: " Terraza ",
          info: false,
        },

        {
          dian: " TO ",
          text: " Torre ",
          info: false,
        },
        {
          dian: " TV ",
          text: " Transversal ",
          info: false,
        },
        {
          dian: " UN ",
          text: " Unidad ",
          info: false,
        },
        {
          dian: " UR ",
          text: " Unidad residencial ",
          info: false,
        },
        {
          dian: " URB ",
          text: " Urbanizacion ",
          info: false,
        },
        {
          dian: " VTE ",
          text: " Variante ",
          info: false,
        },
        {
          dian: " VRD ",
          text: " Vereda ",
          info: false,
        },
        {
          dian: " ZN ",
          text: " Zona ",
          info: false,
        },
        {
          dian: " ZF ",
          text: " Zona franca ",
          info: false,
        },
      ],
      letters: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        " A ",
        " B ",
        " C ",
        " D ",
        " E ",
        " F ",
        " G ",
        " H ",
        " I ",
        " J ",
        " K ",
        " L ",
        " M ",
        " N ",
        " O ",
        " P ",
        " Q ",
        " R ",
        " S ",
        " T ",
        " U ",
        " V ",
        " W ",
        " X ",
        " Y ",
        " Z ",
        " BIS ",
        "LIMPIAR",
        "AGREGAR",
      ],
      listPensiones: [],
      listEps: [],
    };
  },
  computed: {
    getUser() {
      let user = this.$store.getters.getUser;
      let references = this.$store.getters.getReferences;
      if (user) {
        this.loadingAll = false;
        this.info = user;
        this.id = user.profile.id;
        this.img = user.img;
        this.changeImg = false;
        this.clientId = user.client_id;
        this.campaignId = user.campaign_id;
        this.getBirthday();
        this.getWorkTime();
        this.getPersonalReferences(user, references);
      } else {
        this.loadingAll = true;
      }
    },
    performingData() {
      let performing = this.$store.getters.getPerformingData;
      if (performing) {
        this.listActionsPlan = performing.data.data.actions.length;
        this.listPerformance = performing.data.data.performance.length;
      } else {
      }
    },
  },
  methods: {
    addAddress(data) {
      if (data.text == "LIMPIAR") {
        this.formEdit.address = "";
        this.formEdit.addressDian = "";
        this.nomenclatureText = null;
      } else if (data.text == "AGREGAR") {
        this.formEdit.address =
          this.formEdit.address +
          this.nomenclatureText.text +
          this.infoAdditional;
        if (data.hasOwnProperty("dian")) {
          this.formEdit.addressDian =
            this.formEdit.addressDian +
            this.nomenclatureText.dian +
            this.infoAdditional;
        } else {
          this.formEdit.addressDian =
            this.formEdit.addressDian +
            this.nomenclatureText.dian +
            this.infoAdditional;
        }
      } else {
        this.formEdit.address =
          this.formEdit.address + data.text + this.infoAdditional;
        if (data.hasOwnProperty("dian")) {
          this.formEdit.addressDian = this.formEdit.addressDian + data.dian;
        } else {
          this.formEdit.addressDian = this.formEdit.addressDian + data.text;
        }
      }
      this.formEdit.addressDian.trim();
      this.formEdit.address.trim();
      this.nomenclatureText = "";
      this.infoAdditional = "";
    },
    addAddress(data) {
      if (data.text == "LIMPIAR") {
        this.formEdit.address = "";
        this.formEdit.addressDian = "";
        this.nomenclatureText = null;
      } else if (data.text == "AGREGAR") {
        this.formEdit.address =
          this.formEdit.address +
          this.nomenclatureText.text +
          this.infoAdditional;
        if (data.hasOwnProperty("dian")) {
          this.formEdit.addressDian =
            this.formEdit.addressDian +
            this.nomenclatureText.dian +
            this.infoAdditional;
        } else {
          this.formEdit.addressDian =
            this.formEdit.addressDian +
            this.nomenclatureText.dian +
            this.infoAdditional;
        }
      } else {
        this.formEdit.address =
          this.formEdit.address + data.text + this.infoAdditional;
        if (data.hasOwnProperty("dian")) {
          this.formEdit.addressDian = this.formEdit.addressDian + data.dian;
        } else {
          this.formEdit.addressDian = this.formEdit.addressDian + data.text;
        }
      }
      this.formEdit.addressDian.trim();
      this.formEdit.address.trim();
      this.nomenclatureText = "";
      this.infoAdditional = "";
    },
    validateRedirect(data) {
      if (data.status_id == 4) {
        window.open(data.sign, "_blank");
      } else {
        this.$router.push({
          name: "documentDetails",
          params: { id: data.document_sign_id, docUser: data.id },
        });
      }
    },
    voluntary() {
      this.$router.push({ name: "voluntaryRetirement" });
    },
    isValidInList(value, list) {
      return list.includes(value);
  },
    async irAppPerforming() {
      //INFORMACIÓN EXCLUSIVA DE PERFORMING
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: 282,
        appname: "Performing",
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };

      Api.noAuth()
        .saveAppActivity(record)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            console.log("Se registro con exito");
          }
          var token = `?token=${this.tokenB64}`;
          var url = "https://performing.tars.dev/#/auth/validate" + token;

          let newURL = document.createElement("a");
          newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
          newURL.href = url;
          newURL.target = "_blank";
          document.body.appendChild(newURL);
          newURL.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.modal.edit = true;
      this.formEdit = {
        name: this.info.name,
        lastName: this.info.lastname,
        documentExpeditionDate: this.info.document_expedition_date,
        birthDate: this.info.birth_date,
        email: this.info.email,
        personalEmail: this.info.personal_email,
        phone: this.info.phone,
        localPhone: this.info.local_phone,
        civilStatus: this.info.civil_status,
        documentNumber: this.info.document_number,
        documentType: this.info.document_type,
        stratum: this.info.stratum,
        housingType: this.info.housing_type,
        academicLevel: this.info.academic_level,
        gender: this.info.gender,
        residenceNeighborhood: this.info.residence_neighborhood,
        locality: this.info.locality,
        residenceDepartament: this.info.residence_department,
        residenceCity: this.info.residence_city,
        fullName: this.info.name + " " + this.info.lastname,
        age: this.info.age,
        address: this.info.address == null ? "" : this.info.address,
        addressDian:
          this.info.address_dian == null ? "" : this.info.address_dian,
        eps: this.info.eps,
        pension: this.info.pension,
      };
    },
    getToken() {
      var token = localStorage.getItem("token");
      this.token = token;
      this.tokenB64 = localStorage.getItem("tokenB64");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getImg() {
      Api.Auth()
        .getImg(this.infoToken.document)
        .then((res) => {
          if (res.data.data == "https://app.brm.co/imgs/imgPerfil.png") {
            this.img =
              "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png";
          } else {
            this.img = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEpsPension() {
      Api.Auth()
        .getEpsPension()
        .then((res) => {
          this.listEps = res.data.data.eps;
          this.listPensiones = res.data.data.pension;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getShow() {
      Api.noAuth()
        .profile(this.info.document_number)
        .then((res) => {
          this.info = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBirthday() {
      let dateOfBirth = this.info.birth_date;
      let dateOfBirthToString = new Date(dateOfBirth).valueOf();
      let today = new Date(Date.now());
      let formatToday = new Date(today);
      let formatTodaytoString = new Date(formatToday).valueOf();
      let diffInYears = Math.floor(
        (formatTodaytoString - dateOfBirthToString) /
        (1000 * 60 * 60 * 24) /
        365.25
      );
      let getIndex = dateOfBirth.indexOf("-");
      let getYear = parseInt(dateOfBirth.slice(0, getIndex)) + diffInYears + 1;
      let monthAndDays = dateOfBirth.slice(getIndex);
      let birthday = `${getYear}${monthAndDays}`;
      let birthdayToString = new Date(birthday).valueOf();
      let formatBirthday = new Date(birthdayToString);
      let diffInDays = Math.floor(
        (formatBirthday - formatToday) / (1000 * 60 * 60 * 24) + 1
      );
      this.birthday = diffInDays;
    },
    getWorkTime() {
      let dateOfAdmission = this.info.date_admission;
      let dateOfAdmissionToString = new Date(dateOfAdmission).valueOf();

      let formatDateOfAdmission = new Date(dateOfAdmissionToString);
      let today = new Date(Date.now());
      let formatToday = new Date(today);
      let diffInMonths = Math.floor(
        (formatToday - formatDateOfAdmission) / (1000 * 60 * 60 * 24) / 30
      );
      this.workTime = diffInMonths;
    },
    getTecInfo() {
      Api.noAuth()
        .getTecInfo(this.infoToken.document)
        .then((res) => res.json())
        .then(async (data) => {
          this.infoTec = data.data;
          //this.id = res.data.data.profile.id
          //sessionStorage.setItem('id_profile', this.id)
          // console.log(`infoTec -- ${JSON.stringify(typeof this.infoTec.sede)}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveImg() {
      if (this.$refs.form.validate()) {
        this.buttonLoading = true;
        let extension = this.file.type.split("/")[1].toLowerCase();
        if (
          extension !== "png" &&
          extension !== "jpeg" &&
          extension !== "bmp"
        ) {
          this.fileFormat = true;
          this.buttonLoading = false;
          this.$refs.form.reset();
          this.file = null;
          setTimeout(() => {
            this.fileFormat = false;
          }, 3000);
        } else {
          const formData = new FormData();
          formData.append("img", this.file);
          let config = {
            headers: {
              Authorization: "Bearer " + this.tokenB64,
            },
          };
          Api.Auth()
            .saveImg(formData, config)
            .then((res) => {
              this.buttonLoading = false;
              if (res.data.cod == 0) {
                this.changeImg = false;
                setTimeout(() => {
                  this.file = null;
                }, 3000);

                this.changeImg = false;
                this.alert.open = true;
                this.alert.title = "Excelente";
                this.alert.txt =
                  "La fotografía de perfil se cambio correctamente";
                this.alert.type = "success";
                this.file = null;

                // this.getProfile();
                this.$store.dispatch("updateUserProfile");
                this.$store.dispatch("updateUserProfileImg");
              } else {
                this.alert.open = true;
                this.alert.title = "¡Oh no!";
                this.alert.txt = "Intenta mas tarde.....";
                this.alert.type = "error";
              }
            })
            .catch((error) => {
              console.log(error);
              this.buttonLoading = false;
              this.alert.open = true;
              this.alert.title = "¡Oh no!";
              this.alert.txt = "Intenta más tarde... ___";
              this.alert.type = "error";
            });
        }
      }
    },
    updateProfile() {
      // por el momento no se aplica alertar porque no se usa
      if (this.$refs.formE.validate()) {
        this.loadingAll = true;
        Api.noAuth()
          .updateProfile(this.formEdit, this.tokenB64)
          .then((res) => {
            this.loadingAll = false;
            if (res.data.cod == 0) {
              this.modal.edit = false;
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = res.data.message;
              this.alert.type = "success";
              this.getShow();
              console.log(res.data.data);
              //this.$store.dispatch("updateUserInfo", res.data.data);
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = res.data.message;
              this.alert.type = "success";

              this.$store.dispatch("updateUserInfo", res.data.data);
              this.getUser();
            }
          })
          .catch((error) => {
            this.loadingAll = false;
            console.log(error);
          });
      }
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    getPersonalReferences(user, references) {
      this.references = references.filter((reference) => {
        return reference.document_number_referrer === user.document_number;
      });
    },
    getDocumentAudit() {
      Api.Auth()
        .getDocumentAudit(this.tokenB64)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listAudit = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateCarnet() {
      this.loadingAll = true;

      Api.carnet()
        .getCarnet(this.tokenB64)
        .then((res) => {
          this.loadingAll = false;
          const blob = new Blob([res.request.response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Carnet'; // Cambia "nombre_del_archivo" al nombre deseado del archivo
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getDocumentAudit();
    this.getTecInfo();
    this.getEpsPension();
  },
  watch: {
    "formEdit.birthDate": function (n) {
      if (n) {
        var years = moment().diff(n, "years");
        this.formEdit.age = years;
      }
    },
    getUser: function (newCount, oldCount) {
      console.log("");
    },
    performingData: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>

<style>
@import "../../assets/css/main.less";

/*  NUEVOS STYLE V2  */

.card {
  width: 500px;
}

.carnet {
  background-color: #355ae6;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  width: 150px;
  /* ajusta el tamaño según tus necesidades */
  height: 150px;
  /* ajusta el tamaño según tus necesidades */
  border-radius: 50%;
  /* para hacer la imagen redonda */
  overflow: hidden;
  /* oculta cualquier parte de la imagen que se salga del radio del borde */
  border: 5px solid white;
  /* borde blanco de 2px */
  margin-top: 10px;
  /* ajusta el margen superior según tus necesidades */
}

.carnet img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* para que la imagen cubra completamente el contenedor */
}
.show{
  overflow-y: auto;
}
.info-text {
  font-weight: bold;
  color: white;
}

.navidad-text {
  display: flex;
}

.profile__time .v-timeline-item__dot--small {
  height: 15px !important;
  width: 15px !important;
}

.profile__title-alert {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #e15454;
}

.profile__title-date {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #e15454;
}

.profile__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.profile__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.profile__subtitle_data_2 {
  font-family: "RobotoRegular";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #353535;
}

.profile__subtitle_data {
  font-family: "RobotoLight";
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  color: #353535;
}

.profile__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.profile__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #353535;
}

.profile__subtitle_ligth {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #353535;
}

.profile__title_number {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #353535;
}

.profile__badge .v-badge__badge {
  width: 29px;
  height: 29px;
  border-radius: 50px;
  border: 1px solid #fff;
}

.profile__badge .v-badge__badge .v-icon {
  color: inherit;
  font-size: 22px !important;
  height: 20px !important;
  margin: 0 -2px;
  width: 20px !important;
}

.profile__div_number {
  /* width: 105px; */
  height: 184px;
  background: #ffffff;
  border: 0.5px solid #d5dbff;
  border-radius: 7px;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  white-space: inherit !important;
}

.v-tab {
  text-transform: none !important;
  font-family: "RobotoRegular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #898989 !important;
}

/*  */

.profile__btn-voluntary {
  /* width: 100%; */
  /* border-bottom: 1.5px solid #466be3; */
  font-family: "RobotoRegular";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.profile__btn-voluntary:hover {
  background-color: #324ca1 !important;
}

.card-info-per:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.line {
  display: none;
}

.img-profile-g-1 {
  /* border: 3px solid; */
  border-radius: 104px;
  /* border-color: #3b5bc0; */
  /* z-index: 10; */
  position: static;
  height: 120px !important;
  width: 120px !important;
  /* top: 160px;
  left: 10%; */
}

/* .container-img-profile {
   position: absolute;
  z-index: 2 !important;
  top: 170px;
  left: 8%;
} */

.text-blue-actions-p {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: #466be3;
}

.txt-card-text-error {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 57px;
  text-align: center;
  color: #466be3;
}

.text-label-profile {
  color: #141b33 !important;
  text-align: initial;
  font-weight: bold;
}

.camel-case {
  text-transform: capitalize !important;
}

.capi-case {
  text-transform: capitalize;
}

.minus {
  text-transform: lowercase !important;
}

.firstMayus p {
  text-transform: lowercase;
}

.firstMayus p::first-letter {
  text-transform: uppercase;
}

.firstMayus i::first-letter {
  text-transform: uppercase;
}

.line {
  display: none;
}

.profile__banner {
  padding-left: 80px !important;
}

.calendar-info-container {
  justify-content: flex-end;
}

.additional-information-number {
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  font-family: "RobotoRegular";
}

.additional-information-text {
  color: #141b33;
  max-width: 100%;
  font-family: "RobotoRegular";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.personal-information-mobile {
  display: none;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-title-mobile {
  display: flex;
  align-items: center;
}

.rocket-icon {
  height: 50%;
  margin-right: 10px;
}

.mb-sm-profile-main {
  display: none;
}

.total-num {
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  color: #466be3;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 100%;
}

.overflow {
  overflow-y: inherit !important;
}

.title-change-img {
  font-size: 25px;
  color: #466be3;
  font-family: "RobotoMedium";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
}

.imgAvatar {
  display: block;
  max-width: 230px !important;
  max-height: 95px !important;
  width: auto !important;
  height: auto !important;
}

.profile__change-image {
  word-break: break-word;
  max-width: 100%;
}

.profile__button:hover {
  background-color: #324ca1 !important;
}

.profile__border-image {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0;
}

.container-altura {
  height: 534px;
}

@font-face {
  font-family: "AsapSemiBold";
  src: url("./../../assets/font/Asap/Asap-Bold.ttf");
}

@font-face {
  font-family: "ProximaNovaLight";
  src: url("./../../assets/font/ProximaNova/ProximaNova-Light.ttf");
}

@media only screen and (max-width: 1920px) {
  .container-img-profile {
    top: 150px;
    left: 5%;
  }
}

/* LAPTOP 4k */

@media (max-width: 1920px) {
  .container-img-profile {
    top: 230px;
    left: 10%;
  }
}

@media (max-width: 1700px) {
  .container-img-profile {
    top: 200px;
    left: 9%;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
  .container-img-profile {
    top: 190px;
    left: 8%;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
  .container-img-profile {
    top: 160px;
    left: 7%;
  }

  .container-altura {
    height: 577px;
  }

  .container-item-altura {
    height: 225px;
  }
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
  .container-img-profile {
    top: 140px;
  }

  .container-altura {
    height: 573px;
  }

  .container-item-altura {
    height: 220px;
  }
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-profile-main {
    display: none;
  }

  .mb-sm-profile-main {
    display: block;
    text-align: initial;
  }

  .img-banner {
    margin: 0 !important;
    height: 160px;
    position: relative;
    z-index: 2 !important;
    bottom: 1%;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2)) drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }

  /* .container-img-profile {
    left: 38%;
  } */

  .container-img-profile {
    position: absolute;
    z-index: 2;
    top: 140px;
    left: 43%;
  }

  .line {
    display: block;
    background-color: #141b33;
    width: 100%;
    height: 90px;
    position: relative;
    bottom: 5%;
    z-index: 1;
  }

  .text-title-dark-blue-xl-p {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #141b33;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-profile-main {
    display: none;
  }

  .mb-sm-profile-main {
    display: block;
    text-align: initial;
  }

  .img-banner {
    margin: 0 !important;
    height: 160px;
    position: relative;
    z-index: 2 !important;
    bottom: 1%;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2)) drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }

  .container-img-profile {
    /* top: 150px; */
    left: 40%;
  }

  .line {
    display: block;
    background-color: #141b33;
    width: 100%;
    height: 90px;
    position: relative;
    bottom: 5%;
    z-index: 1;
  }

  .text-title-dark-blue-xl-p {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #141b33;
  }
}

@media (max-width: 600px) {
  .container-img-profile {
    /* top: 150px; */
    left: 38%;
  }
}

@media (max-width: 425px) {
  .container-img-profile {
    /* top: 155px; */
    left: 34%;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-profile-main {
    display: none;
  }

  .mb-sm-profile-main {
    display: block;
    text-align: initial;
  }

  .img-banner {
    margin: 0 !important;
    height: 160px;
    position: relative;
    z-index: 2 !important;
    bottom: 1%;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2)) drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }

  .line {
    display: block;
    background-color: #141b33;
    width: 100%;
    height: 90px;
    position: relative;
    bottom: 5%;
    z-index: 1;
  }

  .text-title-dark-blue-xl-p {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #141b33;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-profile-main {
    display: none;
  }

  .mb-sm-profile-main {
    display: block;
    text-align: initial;
  }

  .img-banner {
    margin: 0 !important;
    height: 160px;
    position: relative;
    z-index: 2 !important;
    bottom: 1%;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2)) drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }

  .container-img-profile {
    left: 30%;
  }

  .line {
    display: block;
    background-color: #141b33;
    width: 100%;
    height: 90px;
    position: relative;
    bottom: 5%;
    z-index: 1;
  }

  .text-title-dark-blue-xl-p {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #141b33;
  }

  .total-num {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: #466be3;
  }
}
</style>
